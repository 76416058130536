"use client";

import { isNotFoundError, notFound } from "next/dist/client/components/not-found";
import { useEffect } from "react";

export default function PricingPageError({
  error,
  reset,
}: Readonly<{ error: Error; reset: () => void }>) {
  useEffect(() => {
    // Log the error to an error reporting service
    console.error("app error", error, error.message);
    if (isNotFoundError(error)) {
      notFound();
    }
  }, [error]);

  return (
    <div>
      <h2>Something went wrong!</h2>
      <button
        onClick={
          // Attempt to recover by trying to re-render the segment
          () => reset()
        }
      >
        Try again
      </button>
    </div>
  );
}
